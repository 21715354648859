(function(){

	var ativadorSombra = $('[data-sombra="true"]');

	var alvoClasse = $('body');

	var alvoClasseClassesToggle = alvoClasse.attr('data-toggle');

	ativadorSombra.bind('click',function(){
		alvoClasse.addClass('sombra');
		desligar.fadeIn('500');
	});

	var desligar = $('[data-desligador="true"]');

	desligar.bind('click',function(){
		alvoClasse.removeClass(alvoClasseClassesToggle);
		$(this).fadeOut('500');
	});

	var menuToggle = $('[data-menu-active="true"]');

	menuToggle.bind('click',function(){
		alvoClasse.addClass('menu-active');
	});

})();